import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import Aos from 'aos'
import 'aos/dist/aos.css'

import pic01 from '../assets/images/screens1.png'
import space from '../assets/images/mockupspace.png'
import coffeehouse from '../assets/images/displayyy.png'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
// import pic04 from '../assets/images/pic04.jpg'
import pic05 from '../assets/images/pic05.jpg'
import pic06 from '../assets/images/pic06.jpg'

import pic04 from '../assets/images/netflix-cover.jpg'



const HomeIndex = () => {

    useEffect(()=>{
        Aos.init({ duration: 800 })
      }, [])

    // render() {
        return (
            <Layout>
                <Helmet
                    title="Mary Ojo"
                    meta={[
                        { name: 'description', content: 'My Portfolio' },
                        { name: 'keywords', content: 'sample, something' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                    
                        <article data-aos="fade-up"  style={{backgroundImage: `url(${space})`}}>
                            <header className="major">
                                <h3>My Space</h3>
                                <p>UI - Prototyping</p>
                            </header>
                            <a rel="noreferrer" href="https://www.behance.net/gallery/135488633/myspace-UX-Case-Study" target="_blank" className="link primary"></a>
                            {/* <Link to="#" className="link primary"></Link> */}
                        </article>
                        <article data-aos="fade-up" style={{backgroundImage: `url('https://images.pexels.com/photos/799869/pexels-photo-799869.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')`}}>
                            <header className="major">
                                <h3>CoffeeHouse</h3>
                                <p>UI/UX - Prototyping</p>
                            </header>
                            <a rel="noreferrer" href="https://coffeehouse.maryojo.me" className="link primary"></a>
                        </article>
                        
                        <article data-aos="fade-up" style={{backgroundImage: `url('https://images.pexels.com/photos/1640775/pexels-photo-1640775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')`}}>
                            <header className="major">
                                <h3>Nutricious</h3>
                                <p>UI/Visual Design - Prototyping</p>
                            </header>
                            <a rel="noreferrer" href="https://www.behance.net/gallery/151046221/Nutricious" target="_blank" className="link primary"></a>
                        </article>
                        <article data-aos="fade-up" style={{backgroundImage: `url(${pic01})`}}>
                            <header className="major">
                                <h3>Artaine</h3>
                                <p>UI/UX - Prototyping</p>
                                <p>(COming Soon)</p>
                            </header>
                            <a rel="noreferrer" href="#" className="link primary"></a>
                        </article>
                        {/* <article data-aos="fade-up" style={{backgroundImage: `url('https://assets.nflxext.com/ffe/siteui/vlv3/c8c8a0ad-86d6-45f1-b21d-821afa4e5027/8fc4213b-7fe2-4821-92d2-78ed5238b14d/NG-en-20220801-popsignuptwoweeks-perspective_alpha_website_small.jpg')`}}>
                            <header className="major">
                                <h3>Discord Reimagined</h3>
                                <p>UI Redesign</p>
                            </header>
                            <a rel="noreferrer" href="https://sprightly-torrone-a6b3ec.netlify.app/" target="_blank" className="link primary"></a>
                        </article> */}
                        {/* <article data-aos="fade-up" style={{backgroundImage: `url('https://assets.nflxext.com/ffe/siteui/vlv3/c8c8a0ad-86d6-45f1-b21d-821afa4e5027/8fc4213b-7fe2-4821-92d2-78ed5238b14d/NG-en-20220801-popsignuptwoweeks-perspective_alpha_website_small.jpg')`}}>
                            <header className="major">
                                <h3>Shopverse</h3>
                                <p>Wordpress Website</p>
                            </header>
                            <a rel="noreferrer" href="https://sprightly-torrone-a6b3ec.netlify.app/" target="_blank" className="link primary"></a>
                        </article> */}
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Thoughts</h2>
                            </header>
                            <blockquote>Every great design begins with an even better story." -- Lorinda Mamo, designer</blockquote>
                            {/* <ul className="actions">
                                <li><Link to="/landing" className="button next">Get Started</Link></li>
                            </ul> */}
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
// 


export default HomeIndex